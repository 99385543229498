import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ConfirmationModalProps {
    openDialog: boolean;
    onClose: () => void;
    onConfirm: () => void;
    message?: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ openDialog, onClose, onConfirm, message }) => {
    return (
        <Modal
            open={openDialog}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={styles.modal}>
                <Box sx={styles.header}>
                    <Typography id="modal-title" variant="h6" component="h2" sx={styles.title}>
                        FX ORDER - potwierdzenie
                    </Typography>
                    <Button
                        onClick={onClose}
                        sx={styles.closeButton}
                    >
                        <CloseIcon sx={styles.closeIcon} />
                    </Button>
                </Box>
                <Box sx={styles.description}>
                    <Typography variant="body1">
                        {message}
                    </Typography>
                </Box>
                <Box sx={styles.buttons}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onConfirm}
                        sx={styles.button}
                    >
                        Tak
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onClose}
                        sx={styles.button}
                    >
                        Nie
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

const styles = {
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: 8,
        width: 450,
    },
    header: {
        backgroundColor: 'primary.main',
        padding: '10px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '8px 8px 0 0',
    },
    title: {
        color: 'white',
        textAlign: 'center',
    },
    closeButton: {
        color: 'white',
        padding: '0',
        minWidth: 'auto',
    },
    closeIcon: {
        fontSize: '24px',
    },
    description: {
        backgroundColor: 'white',
        padding: '8px 16px',
        display: 'flex',
        marginTop: '16px',
    },
    buttons: {
        margin: '20px 0',
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
    },
    button: {
        width: '44%',
    },
};


export default ConfirmationModal;
