import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { CommonTextControl } from "../common/CommonFormControl";
import { ChangeEvent } from "react";
import { store } from "../../app/store";
import { amountsChanged, prevAmountChanged, subscribeCurrencyRatesForAmount } from "./currencyRatesSlice";
import { amountChanged } from "./currencyRatesSlice";
import { changeTab } from "../nav/navSlice";
import { NavTab } from "../nav/navTypes";
import { Product, SideSymbol } from "../exchange/TransactionForm";
import { styled } from "@mui/material/styles";
import { handleCurrencyChange, handleProductChange, handleValueChange } from "../exchange/tranactionForm";
import { StyledButton } from "../common/controllers";
import { CurrentCurrencyRate } from "./CurrencyRatesState";
import {setDefaultDateAndAccountsFields} from "../exchange/transactionFormSlice";

const StyledTableCell = styled(TableCell)`
    &:hover {
        cursor: pointer;
    }
`;

export default function RatesTable() {
    const rates = useAppSelector(state => state.currencyRates.currentCurrencyRates);
    const amounts = useAppSelector(state => state.currencyRates.currencyAmounts);
    const prevAmounts = useAppSelector(state => state.currencyRates.prevCurrencyAmounts);
    const { t } = useTranslation();
    
    return (
        <TableContainer component={Paper} sx={{ maxWidth: 600, marginLeft: '8px' }}>
            <Table aria-label='simple table'>
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>{t('currency_pair')}</TableCell>
                        <TableCell align='center'>{t('buy_rate')}</TableCell>
                        <TableCell align='center'>{t('sell_rate')}</TableCell>
                        <TableCell align='center'>{t('currency_amount')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rates.map((row, idx) => (
                        <TableRow
                            key={row.waluta}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center' component='th' scope='row'>{row.waluta}</TableCell>
                            <StyledTableCell
                                align='center'
                                onClick={() => handleClick(row.waluta, SideSymbol.K, getAmount(amounts, idx).toString())}
                                style={{ display: 'table-cell'}}
                            >
                                    {row.kursSprzedazy?.toFixed(4) ?? '...'}
                            </StyledTableCell>
                            <StyledTableCell
                                align='center'
                                onClick={() => handleClick(row.waluta, SideSymbol.S, getAmount(amounts, idx).toString())}
                                style={{ display: 'table-cell'}}
                            >
                                    {row.kursKupna?.toFixed(4) ?? '...'}
                            </StyledTableCell>
                            <TableCell sx={{flexDirection: 'row', display: 'flex'}}>
                                <CommonTextControl
                                    name=''
                                    callback={handleAmountChange}
                                    onBlurCallback={() =>
                                        subscribeAmount(row.waluta, amounts[idx], prevAmounts[idx], idx)
                                }
                                    value={getAmount(amounts, idx).toString()}
                                />
                                <Typography variant='body2' sx={{ alignSelf: 'center' }}>
                                    {row.waluta.slice(0, 3)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                            <StyledButton
                                fullWidth
                                variant='contained'
                                color='primary'
                                onClick={() => setBaseAmounts(rates, prevAmounts)}
                            >
                                {t('minimum_amounts')}
                            </StyledButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function handleClick(currency: string, side: SideSymbol, amount: string) {
    handleProductChange(Product.FX_SPOT);
    handleCurrencyChange(currency);
    handleValueChange({
        valType: 'strona',
        value: side
    });
    handleValueChange({
        valType: 'amount',
        value: amount
    });
    store.dispatch(setDefaultDateAndAccountsFields());
    store.dispatch(changeTab(NavTab.EXCHANGE));
}

function setBaseAmounts(rates: CurrentCurrencyRate[], prevAmounts: number[]) {
    store.dispatch(amountsChanged(rates.map(rate => rate.kwotaBazowa)));
    rates.forEach((rate, index) =>
        subscribeAmount(rate.waluta, rate.kwotaBazowa, prevAmounts[index], index)
    );
}

function subscribeAmount(currency: string, amount: number, prevAmount: number, index: number) {
    if (!amount || amount === prevAmount) {
        return
    }
    store.dispatch(subscribeCurrencyRatesForAmount({kwota: amount, waluta: currency}));
    store.dispatch(prevAmountChanged([index, amount]));
}

export function handleAmountChange(e: ChangeEvent<HTMLInputElement>) {
    if (shouldUpdateAmount(e.target.value)) {
        handleNumericValueChange(e);
    }
}

function handleNumericValueChange(e: ChangeEvent<HTMLInputElement>) {
    const rowID = document.getElementById(e.target.id)?.closest('tr') ?? null;
    const rowIndex = rowID != null ? rowID.rowIndex : null;
    const amount = e.target.value
        .replace(',', '.')
        .replace(/^0+/, '')
        .replace(/^\./, '0.');
    store.dispatch(amountChanged([rowIndex != null ? rowIndex - 1 : null, amount === '' ? 0 : amount]));
}

function shouldUpdateAmount(amount: string) {
    return /^\d*([.,]\d{0,2})?$/
        .test(amount);
}

function getAmount(amounts: number[], index: number) {
    if (amounts != null) {
        if (amounts[index] != null) {
            return amounts[index];
        } else {
            return 0;
        }
    } else {
        return 0;
    }
}
