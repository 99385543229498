type StatePropName = 'statusTransakcji' | 'status';

export type Transaction = {
    [key in StatePropName]: string;
} & {
    nrReferencyjny: string;
    czasDataZawarciaTransakcji: string | number;
    klientNazwaOperatora: string;
    typTransakcji: string;
    waluta: string;
    strona: string;
    kwota: number;
    dataWaluty: number;
    dataZakonczenia: number;
    termin: string;
    cenaOprocentowanie: number;
    statusTransakcjiOpis: string;
    czasWygasniecia: number;
    produkt: string;
};

export enum TableType {
    DEFAULT,
    HISTORY,
    TODAYS,
    FX_ORDER
}

export namespace TableType {
    export function isDefault(type: TableType) {
        return type === TableType.DEFAULT;
    }

    export function isHistory(type: TableType) {
        return type === TableType.HISTORY;
    }

    export function isDefaultOrHistory(type: TableType) {
        return isDefault(type) || isHistory(type);
    }

    export function isDefaultOrFxOrder(type: TableType) {
        return isDefault(type) || type === TableType.FX_ORDER;
    }
}

export enum TransactionType {
    FX_SPOT = 'FX SPOT',
    FX_ORDER = 'FX ORDER',
    DEPOZYT_NEG = 'DEPOZYT NEG'
}

export enum SortOrder {
    DESC = 'desc',
    ASC = 'asc',
}