import { useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SortOrder, TableType, Transaction, TransactionType } from './Transaction';
import { formatAmount, millisToDateString, millisToDateTimeString } from '../../services/utils';
import { Side, TransactionStatus } from "../exchange/TransactionForm";
import { getTransaction, openDialog, productChanged } from "../exchange/transactionFormSlice";
import { useAppDispatch } from "../../app/hooks";
import ConfirmWindow from "../exchange/confirmWindow";
import Countdown from "react-countdown";
import { CountdownRenderProps } from "react-countdown/dist/Countdown";
import { fetchTransactions } from "./transactionsListSlice";
import { fetchHistory } from "../history/historySlice";

export const StyledTable = styled(Table)(({ theme }) => `
    min-width: 450;
    border-radius: ${theme.shape};
`);

interface TransactionsTableProps {
    transactions: Transaction[];
    type?: TableType;
    totalTransactions?: number;
}

interface HeadCell {
    id: string;
    label: string;
    sortable: boolean;
    shouldBeVisible: boolean
}

export default function TransactionsTable(
    { transactions, totalTransactions, type = TableType.DEFAULT }: Readonly<TransactionsTableProps>
) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isDesc, setIsDesc] = useState<boolean>(true);
    const [orderBy, setOrderBy] = useState<string>('nrReferencyjny');
    const [page, setPage] = useState(0);
    const isHistoryPage = !TableType.isDefaultOrFxOrder(type);
    const rowsPerPage = 15;

    const headCells: readonly HeadCell[] = [
        { id: 'nrReferencyjny', label: 'ref_number', sortable: true, shouldBeVisible: true },
        { id: 'czasDataZawarciaTransakcji', label: 'transaction_timestamp', sortable: isHistoryPage, shouldBeVisible: TableType.isDefaultOrHistory(type) },
        { id: 'klientNazwaOperatora', label: 'allowed_person', sortable: true, shouldBeVisible: TableType.isDefaultOrHistory(type) },
        { id: 'typTransakcji', label: 'type', sortable: true, shouldBeVisible: true },
        { id: 'waluta', label: 'currency', sortable: true, shouldBeVisible: true },
        { id: 'strona', label: 'side', sortable: true, shouldBeVisible: true },
        { id: 'kwota', label: 'amount', sortable: !isHistoryPage, shouldBeVisible: true },
        { id: 'dataWaluty', label: 'start_date', sortable: false, shouldBeVisible: TableType.isDefaultOrHistory(type) },
        { id: 'dataZakonczenia', label: 'end_date', sortable: !isHistoryPage, shouldBeVisible: true },
        { id: 'termin', label: 'term', sortable: false, shouldBeVisible: TableType.isDefaultOrHistory(type) },
        { id: 'cenaOprocentowanie', label: 'price', sortable: !isHistoryPage, shouldBeVisible: true },
        { id: 'statusTransakcji', label: 'status', sortable: isHistoryPage, shouldBeVisible: TableType.isDefaultOrHistory(type) },
        { id: 'czasWygasniecia', label: 'offer_timeout', sortable: !isHistoryPage, shouldBeVisible: TableType.isDefaultOrFxOrder(type) },
    ];

    const getParams = (pageNo: number) => ({
            column: orderBy,
            desc: isDesc,
            pageNo,
            pageSize: rowsPerPage
        }
    );

    useEffect(() => {
        if (isHistoryPage) {
            dispatch(fetchHistory({
                sortColumn: orderBy,
                sortOrder: isDesc ? SortOrder.DESC : SortOrder.ASC,
            }))
                .catch(error => console.error('Error fetching history:', error));
        } else {
            const params = getParams(1);
            dispatch(fetchTransactions(params))
                .catch(error => console.error('Error fetching transactions:', error));
        }
    }, [isDesc, orderBy]);


    const handleRequestSort = (property: string) => {
        const order = orderBy === property ? !isDesc : false;
        setIsDesc(order);
        setOrderBy(property);
        setPage(0);
    };

    const handleChangePage = (event: any, newPage: number) => {
        const params = getParams(newPage + 1);
        setPage(newPage);
        dispatch(fetchTransactions(params));
    };

    return <>
        <TableContainer
            component={Paper}
            sx={{
                marginBottom: '16px',
                '& .MuiTableCell-root': { padding: isHistoryPage ? '16px' : '14px' },
                overflowX: 'none'
                }}
        >
            <StyledTable size='medium' aria-label='simple table'>
                <TableHead>
                    <TableRow>
                        {headCells.map((headCell) => (
                                headCell.shouldBeVisible && (
                                    <TableCell
                                        key={headCell.id}
                                        align={'center'}
                                        sortDirection={orderBy === headCell.id && isDesc ? (isDesc ? SortOrder.DESC : SortOrder.ASC) : false}
                                    >
                                        {headCell.sortable ? (
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id && isDesc ? SortOrder.DESC : SortOrder.ASC}
                                                onClick={() => handleRequestSort(headCell.id)}
                                            >
                                                {t(headCell.label)}
                                            </TableSortLabel>
                                        ) : (
                                            <>{t(headCell.label)}</>
                                        )}
                                    </TableCell>
                                )
                            )
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions.map((row) => (
                        <TableRow
                            key={row.nrReferencyjny}
                            onClick={TableType.isDefaultOrHistory(type) ? () => handleClick(row.nrReferencyjny, row.produkt) : undefined}
                            sx={TableType.isDefaultOrHistory(type) ? { '&:hover': { cursor: 'pointer' } } : undefined}
                        >
                            <TableCell align='center'>{row.nrReferencyjny}</TableCell>
                            {TableType.isDefaultOrHistory(type) &&
                                <>
                                    <TableCell align='center'>{TableType.isHistory(type) ?
                                        millisToDateTimeString(row.czasDataZawarciaTransakcji as number) :
                                        row.czasDataZawarciaTransakcji
                                    }</TableCell>
                                    <TableCell align='center'>{row.klientNazwaOperatora}</TableCell>
                                </>
                            }
                            <TableCell align='center'>{row.typTransakcji}</TableCell>
                            <TableCell align='center'>{row.waluta}</TableCell>
                            <TableCell align='center'>{t(Side[row.strona as keyof typeof Side])}</TableCell>
                            <TableCell align='center'>{formatAmount(row.kwota)}</TableCell>
                            {TableType.isDefaultOrHistory(type) &&
                                <TableCell align='center'>{millisToDateString(row.dataWaluty)}</TableCell>
                            }
                            <TableCell align='center'>{millisToDateString(row.dataZakonczenia)}</TableCell>
                            {TableType.isDefaultOrHistory(type) &&
                                <TableCell align='center'>{row.termin}</TableCell>
                            }
                            <TableCell align='center' sx={
                                TableType.isDefault(type) && row.statusTransakcji === TransactionStatus.F ?
                                    { fontWeight: 'bold' } : {}
                            }>{formatAmount(row.cenaOprocentowanie, (TransactionType.DEPOZYT_NEG === row.typTransakcji ? 2 : 4))}</TableCell>
                            {TableType.isDefaultOrHistory(type) &&
                                <TableCell align='center' sx={
                                    TableType.isDefault(type) && row.statusTransakcji === TransactionStatus.F ?
                                        { color: 'primary.main', textTransform: 'uppercase', fontWeight: 'bold' } : {}
                                }>
                                    {row.statusTransakcjiOpis}
                                </TableCell>
                            }
                            {TableType.isDefaultOrFxOrder(type) &&
                                <TableCell align='center'>{row.statusTransakcji === TransactionStatus.F ?
                                    <Countdown
                                        date={row.czasWygasniecia}
                                        renderer={renderer}
                                    /> :
                                    millisToDateTimeString(row.czasWygasniecia)
                                }</TableCell>
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </TableContainer>
        {!isHistoryPage && <TableFooter sx={{ position: 'fixed', bottom: 35, left: 0, right: 0, backgroundColor: '#fff', zIndex: 1 }}>
            <TablePagination
                component="div"
                count={totalTransactions ?? transactions.length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
                page={page}
                onPageChange={handleChangePage}
            />

        </TableFooter>}
        <ConfirmWindow tableType={type}/>
    </>;

    function handleClick(transactionId: string, productId: string) {
        dispatch(getTransaction(transactionId));
        dispatch(productChanged(productId));
        dispatch(openDialog(true));
    }

    function renderer({ formatted: { minutes, seconds }}: CountdownRenderProps) {
        return (
            <Typography align='center' variant='body2' sx={{ fontWeight: 'bold' }}>
                {minutes}:{seconds}
            </Typography>
        );
    }
}
