import { format } from "date-fns";
import { Transaction } from "../features/transactions/Transaction";
import { DoneTransaction } from "../features/start/startTypes";
import { SideSymbol } from "../features/exchange/TransactionForm";

export function parseDate(date?: number) {
    if (date) {
        return new Date(date).toISOString().slice(0, 10);
    }
    return '';
}

export function parseDateAndTime(date?: number) {
    if (date) {
        const dateStr = new Date(date).toISOString();
        return dateStr.slice(0, 10) + ' ' + dateStr.slice(11, 19);
    }
    return '';
}

export function parseDateIntoString(date: Date) {
    return new Date(date).toISOString().slice(0, 10);
}

export function dateToDateString(date?: Date | null) {
    return date && format(date, 'yyyy-MM-dd');
}

export function dateToTimeString(date?: Date | null) {
    return date && format(date, 'HH:mm:ss');
}

export function dateToDateTimeString(date?: Date | null) {
    return date && format(date, 'yyyy-MM-dd HH:mm:ss');
}

export function millisToDate(millis?: number) {
    return millis ? new Date(millis) : null;
}

export function millisToDateString(millis?: number) {
    return dateToDateString(millisToDate(millis));
}

export function millisToTimeString(millis?: number) {
    return dateToTimeString(millisToDate(millis));
}

export function millisToDateTimeString(millis?: number) {
    return dateToDateTimeString(millisToDate(millis));
}

export function changeSidePointOfView(side: SideSymbol | string) {
    if (side === SideSymbol.K) {
        return SideSymbol.S;
    } else if (side === SideSymbol.S) {
        return SideSymbol.K;
    } else {
        return side;
    }
}

export function mapTransactions(transactions: Transaction[]) {
    return transactions.map((transaction: Transaction) => {
        transaction.strona = changeSidePointOfView(transaction.strona);
        return transaction;
    });
}

export function mapDoneTransactions(transactions: DoneTransaction[]) {
    return transactions.map((transaction: DoneTransaction) => {
        transaction.bidOffer = changeSidePointOfView(transaction.bidOffer);
        return transaction;
    });
}

export function formatAmount(amount?: number, precision: number = 2) {
    if (!amount) {
        return amount;
    }

    let result = new Intl.NumberFormat('pl-PL', {
        useGrouping: true,
        minimumFractionDigits: precision,
        maximumFractionDigits: precision
    })
        .format(amount);

    const EXCEPTIONAL_RESULT_LENGTH = 7;
    const isValidFormat = /^\d{4}([.,]\d{0,4})?$/.test(result);
    if (result.length === EXCEPTIONAL_RESULT_LENGTH && isValidFormat ) {
        //might be nonbreaking space: &nbsp; instead of " "
        result = result[0] + " " + result.substring(1)
    }
    return result;
}

export function isBsClient(clientId?: string) {
    return clientId?.includes('_') ?? false;
}
