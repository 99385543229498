export default interface TechMessageState {
    techMessages: TechMessage[];
    shouldShowNewMessages: boolean;
}

export interface TechMessage {
    komId: number;
    czasNadania: number;
    tresc: string;
    czasWaznosci: number;
    nadawca: string;
    przeczytany: string
}

export enum MessageStatus {
    Received = "1",
    Unreceived = "0"
}

export function newTechMessagesState(): TechMessageState {
    return {
        techMessages: [],
        shouldShowNewMessages: false
    };
}
