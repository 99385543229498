import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { NavTab } from "./navTypes";
import { fetchTodaysTransactions, fetchFxOrderTransactions } from "../start/startSlice";
import { fetchTransactions, hideTransactionsBadge } from "../transactions/transactionsListSlice";
import { getTechMessages, hideNewMessages } from "../tech_messages/techMessagesSlice";
import { getCurrentCurrencyRates } from "../currency_rates/currencyRatesSlice";

const initialState = {
    index: NavTab.START
};

export function changeTab(index: NavTab) {
    return async (dispatch: any) => {
        dispatch(tabChanged(index));

        if (index === NavTab.START) {
            dispatch(fetchTodaysTransactions())
            dispatch(fetchFxOrderTransactions())
        } else if (index === NavTab.TRANSACTIONS) {
            dispatch(fetchTransactions());
            dispatch(hideTransactionsBadge())
        } else if (index === NavTab.NOTIFICATIONS) {
            dispatch(getTechMessages());
            dispatch(hideNewMessages())
        } else if (index === NavTab.CURRENCY_RATES) {
            dispatch(getCurrentCurrencyRates());
        }
    };
}

export const navSlice = createSlice({
    name: 'nav',
    initialState,
    reducers: {
        tabChanged: (state, action: PayloadAction<number>) => {
            state.index = action.payload;
        }
    }
});

export const { tabChanged } = navSlice.actions;
export const selectNav = (state: RootState) => state.nav.index;

export default navSlice.reducer;
