import { useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { DesktopDatePicker, LocalizationProvider as LocalizationProviderLab } from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { InputLabel, Select, SelectChangeEvent, TextField } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns as AdapterDateFnsPickers } from '@mui/x-date-pickers/AdapterDateFns';
import { StyledFormControl } from './controllers';
import { pl } from "date-fns/locale";

interface InputProps {
    name: string;
    label?: string;
    translatedName?: string;
}

interface SelectProps extends InputProps {
    items: JSX.Element[];
    value: string;
    callback: (e: SelectChangeEvent) => any;
}

interface TextProps extends InputProps {
    required?: boolean;
    value: string;
    callback: (e: ChangeEvent<HTMLInputElement>) => any;
    onBlurCallback?: any;
    inputProps?: any;
}

interface PickerProps extends InputProps {
    value: Date | null;
    callback: (e: any, keyboardInputValue?: string) => any;
}

interface DateProps extends PickerProps {
    minDate?: Date;
    maxDate?: Date;
    shouldDisableDate?: (day: Date) => boolean;
}

interface TimeProps extends PickerProps {
    minTime?: Date;
    maxTime?: Date;
}

export function CommonSelectControl(props: SelectProps) {
    const { t } = useTranslation();
    const label = props.translatedName ?? t(props.label ?? props.name);
    const labelId = `type${props.name}`;

    return (
        <StyledFormControl sx={{ gridArea: props.name }} variant='outlined'>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                labelId={labelId}
                name={props.name}
                onChange={props.callback}
                label={label}
                defaultValue=''
                value={props.value}
            >
                {props.items}
            </Select>
        </StyledFormControl>
    );
}

export function CommonTextControl(props: TextProps) {
    const { t } = useTranslation();
    const label = t(props.name);
    const labelId = `type${props.name}`;

    return (
        <StyledFormControl sx={{ gridArea: props.name }}>
            <InputLabel id={labelId}></InputLabel>
            <TextField
                required={props.required}
                name={props.name}
                onChange={props.callback}
                onBlur={props.onBlurCallback}
                label={label}
                value={props.value}
                inputProps={props.inputProps}
            />
        </StyledFormControl>
    );
}

export function CommonDateControl(props: DateProps) {
    const { t } = useTranslation();
    const label = t(props.name);

    return (
        <StyledFormControl sx={{ gridArea: props.name }} variant='outlined'>
            <LocalizationProviderLab dateAdapter={AdapterDateFns} locale={pl}>
                <DesktopDatePicker
                    label={label}
                    value={props.value}
                    onChange={props.callback}
                    renderInput={params => <TextField {...params} />}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    shouldDisableDate={props.shouldDisableDate}
                />
            </LocalizationProviderLab>
        </StyledFormControl>
    );
}

export function CommonTimeControl(props: TimeProps) {
    const { t } = useTranslation();
    const label = t(props.name);
    const [view, setView] = useState('');

    useEffect(() => {
        const resetColors = () => {
            const options = document.querySelectorAll(".MuiClockNumber-root");
            options.forEach((option) => {
                (option as HTMLElement).style.color = "";
            });
        };

        const timeout = setTimeout(() => {
            resetColors();
            const options = document.querySelectorAll(".MuiClockNumber-root");
            options.forEach((option) => {
                const isDisabled = option.classList.contains('Mui-disabled') || (option.hasAttribute('aria-disabled') && option.getAttribute('aria-disabled') === 'true');
                const isSelected = option.classList.contains('Mui-selected') || (option.hasAttribute('aria-selected') && option.getAttribute('aria-selected') === 'true');

                (option as HTMLElement).style.color = isDisabled ? "darkgray" : isSelected ? "white" : "black";
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [view]);

    return (
        <StyledFormControl sx={{ gridArea: props.name }} variant='outlined'>
            <LocalizationProvider dateAdapter={AdapterDateFnsPickers}>
                <TimePicker
                    label={label}
                    value={props.value}
                    onChange={(newValue) => {
                        setView("");
                        props.callback(newValue);
                    }}
                    minTime={props.minTime}
                    maxTime={props.maxTime}
                    renderInput={(params) => <TextField {...params} />}
                    ampm={false}
                    onOpen={() => setView('hours')}
                    onViewChange={() => setView('minutes')}
                />
            </LocalizationProvider>
        </StyledFormControl>
    );
}
