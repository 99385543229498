import { Box } from '@mui/material';
import RatesTable from './RatesTable';
import React from "react";
import CurrencyChart from "./CurrencyChart";
import {useMediaQuery} from "@mui/system";

function CurrencyRates() {
    const isDesktop = useMediaQuery('(min-width:1300px)');

    return (
        <Box sx={{ display: 'flex', flexDirection: isDesktop ? 'row' : 'column', gap: 5 }}>
            <RatesTable />
            <CurrencyChart />
        </Box>
    );
}

export default CurrencyRates;
