export interface CurrencyRates {
    options: CurrencyRatesOptions;
    currencyPair: string;
    currentCurrencyRates: CurrentCurrencyRate[];
    currencyAmounts: number[];
    prevCurrencyAmounts: number[];
    side: string;
    interval: string;
    chartData: ChartData;
    loading: boolean;
}

export interface CurrentCurrencyRate {
    waluta: string;
    kursKupna: number;
    kursSprzedazy: number;
    status: string;
    kwotaBazowa: number;
}

export interface ChartData {
    labels: Date[],
    datasets: DataSet[]
}

export interface CurrencyRatesOptions {
    currencyPairs: string[];
    sides: Side[];
    intervals: Interval[];
}

export interface DataSet {
    data: string[];
}

export interface Side {
    id: string;
    name: string;
}

export interface Interval {
    id: string;
    name: string;
}

export interface Rate {
    czas: string;
    kurs: string;
}

export interface RateDto {
    rateDTO: RateDtoData
}

export interface RateDtoData {
    czas: string,
    kurs: string,
    waluta: string,
    strona: string,
    typ: string
}

export interface CurrencyAmountPair {
    waluta: string;
    kwota: number;
}

export function newCurrencyRates(): CurrencyRates {
    return {
        options: {
            currencyPairs: [],
            sides: [],
            intervals: []
        },
        currencyPair: '',
        currentCurrencyRates: [],
        currencyAmounts: [],
        prevCurrencyAmounts: [],
        side: '',
        interval: '',
        loading: false,
        chartData: {
            labels: [],
            datasets: []
        }
    };
}
