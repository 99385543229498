import { createAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { client } from '../../api/client';
import { RootState } from '../../app/store';
import Config from '../../config/Config';
import { fetchProductTypesForForm } from '../exchange/transactionFormSlice';
import { ClientProduct } from '../login/User';
import {SortOrder, Transaction} from '../transactions/Transaction';
import { newFilterForm, newFilterValues, Status, Values } from './FilterForm';
import { mapTransactions } from "../../services/utils";

const historyAdapter = createEntityAdapter<Transaction>({
    selectId: (transaction) => transaction.nrReferencyjny
});

const initialState = historyAdapter.getInitialState({
    status: '',
    entities: {},
    sort: '',
    filter: newFilterForm()
});

const fetchHistoryFailed = createAction<string>('history/fetch/failed');
const fetchHistorySuccess = createAction<Transaction[]>('history/fetch/success');

const fetchTransactionStatusesSuccess = createAction<Status[]>('history/transactionStatuses/fetch/success');
const fetchTransactionStatusesFailed = createAction<string>('history/transactionStatuses/fetch/failed');
export const fetchProductTypesForFilter = createAction<ClientProduct[]>('history/fetchProductTypes');

export function fetchTransactionStatuses() {
    return async (dispatch: any, getState: any) => {
        try {
            const response = await client.get(`${Config.dataUrl}/dictionary/transactionStatuses`);
            dispatch(fetchTransactionStatusesSuccess(response));
        } catch (error: any) {
            dispatch(fetchTransactionStatusesFailed(error));
        }
    };
}

export interface SortedHistory {
    currentPage?: number,
    pageSize?: number,
    totalItems?: number,
    sortColumn: string,
    sortOrder: string
}

const defaultSortPagination: SortedHistory = {
    currentPage: 1,
    pageSize: 9999,
    totalItems: 9999,
    sortColumn: 'nrReferencyjny',
    sortOrder: SortOrder.DESC
}

export function fetchHistory(params?: SortedHistory) {
    return async (dispatch: any, getState: any) => {
        const state = getState();

        const parameters = {
            ...state.history.filter.values,
            ...defaultSortPagination,
            ...params
        };

        try {
            const response = await client.post(
                `${Config.dataUrl}/transactions/listFilteredTransactions`,
                parameters
            );
            dispatch(fetchHistorySuccess(response.transactions));
        } catch (error: any) {
            dispatch(fetchHistoryFailed(error));
        }
    };
}

export function exportExcel() {
    return async (dispatch: any, getState: any) => {
        const state = getState();

        const parameters = state.history.filter.values;

        let url = `${Config.dataUrl}/transactions/exportToFile`;

        url += '?dateFrom=' + (parameters.dateFrom != null ? parameters.dateFrom : '');
        url += '&dateTo=' + (parameters.dateTo != null ? parameters.dateTo : '');
        url += '&status=' + (parameters.status != null ? parameters.status : '');
        url += '&currency=' + (parameters.currency != null ? parameters.currency : '');
        url += '&product=' + (parameters.product != null ? parameters.product : '');
        url += '&amount=' + (parameters.amount != null ? parameters.amount : '');

        window.open(url);
    };
}

const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        historyFilterValueChanged: (state, action) => {
            const valType: keyof Values = action.payload.valType;
            state.filter.values[valType] = action.payload.value;
        },
        historyFilterDateChanged: (state, action) => {
            const valType: keyof Values = action.payload.valType;
            state.filter.values[valType] = action.payload.value;
        },
        clearFilter: (state, action) => {
            state.filter.values = newFilterValues();
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchHistorySuccess, (state, action) => {
                historyAdapter.setAll(state, mapTransactions(action.payload));
                state.status = 'idle';
            })
            .addCase(fetchHistoryFailed, (state, action) => {
                state.status = 'idle';
            })
            .addCase(fetchTransactionStatusesSuccess, (state, action) => {
                state.filter.options.statuses = action.payload;
            })
            .addCase(fetchTransactionStatusesFailed, (state, action) => {
                state.status = 'idle';
            })
            .addCase(fetchProductTypesForForm, (state, action) => {
                state.filter.options.products = action.payload;
            });
    }
});

export default historySlice.reducer;

export const {
    historyFilterValueChanged,
    historyFilterDateChanged,
    clearFilter
} = historySlice.actions;

export const {
    selectAll: selectHistory
} = historyAdapter.getSelectors((state: RootState) => state.history);
