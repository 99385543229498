import { styled } from '@mui/material/styles';
import { useSelector } from "react-redux";
import { Badge, Box, Tab, Tabs, Typography } from '@mui/material';
import TransactionsTable from '../transactions/transactionsList';
import History from '../history/history';
import TransactionForm from '../exchange/tranactionForm';
import {
    selectShouldShowTransactionsBadge,
    selectTotalTransactions,
    selectTransactions
} from '../transactions/transactionsListSlice';
import './nav.css';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { changeTab, selectNav } from './navSlice';
import { useTranslation } from 'react-i18next';
import CurrencyRates from '../currency_rates/CurrencyRates';
import TechMessagesTable from '../tech_messages/techMessages';
import { selectTechMessages } from '../tech_messages/techMessagesSlice';
import { NavTab } from "./navTypes";
import TableRates from "../table_rates/TableRates";
import Start from "../start/Start";
import Help from "../help/Help";

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component='span' variant='body2'>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const StyledDiv = styled('div')(({ theme }) => `
    background-color: ${theme.palette.background.paper};
`);

export default function Nav() {
    const value = useAppSelector(selectNav);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const transactions = useAppSelector(selectTransactions);
    const totalTransactions = useSelector(selectTotalTransactions);
    const { techMessages, shouldShowNewMessages } = useAppSelector(selectTechMessages);
    const shouldShowTransactionsBadge = useAppSelector(selectShouldShowTransactionsBadge);

    return (
        <>
            <Box sx={{
                borderBottom: 1,
                borderColor: 'divider',
                gridRow: '3 / 4'
            }}>
                <Tabs value={value} onChange={(e, v) => handleTabChanged(v)} aria-label='basic tabs example'>
                    <Tab label={t('start')} {...a11yProps(NavTab.START)} />
                    <Tab label={t('new_transaction')} {...a11yProps(NavTab.EXCHANGE)} />
                    <Tab label={
                        <Badge color='primary' variant='dot' invisible={!shouldShowTransactionsBadge}>{t('transactions')}</Badge>
                    } {...a11yProps(NavTab.TRANSACTIONS)} />
                    <Tab label={t('history')} {...a11yProps(NavTab.HISTORY)} />
                    <Tab label={t('exchange_rates')} {...a11yProps(NavTab.CURRENCY_RATES)} />
                    <Tab label={t('table_rates')} {...a11yProps(NavTab.TABLE_RATES)} />
                    <Tab
                        label={t(shouldShowNewMessages ? 'new_message' : 'messages')}
                        {...a11yProps(NavTab.NOTIFICATIONS)}
                        sx={{ fontWeight: shouldShowNewMessages ? 'bold' : 'inherit', color: shouldShowNewMessages ? 'primary.main' : 'rgba(0, 0, 0, 0.6)' }}
                    />
                    <Tab label={t('help')} {...a11yProps(NavTab.HELP)} />
                </Tabs>
            </Box>
            <Box sx={{
                gridRow: '4 / 22',
                overflow: 'auto'
            }}>
                <TabPanel value={value} index={NavTab.START}>
                    <Start />
                </TabPanel>
                <TabPanel value={value} index={NavTab.EXCHANGE}>
                    <TransactionForm />
                </TabPanel>
                <TabPanel value={value} index={NavTab.TRANSACTIONS}>
                    <TransactionsTable transactions={transactions} totalTransactions={totalTransactions} />
                </TabPanel>
                <TabPanel value={value} index={NavTab.HISTORY}>
                    <History />
                </TabPanel>
                <TabPanel value={value} index={NavTab.CURRENCY_RATES}>
                    <CurrencyRates />
                </TabPanel>
                <TabPanel value={value} index={NavTab.TABLE_RATES}>
                    <TableRates />
                </TabPanel>
                <TabPanel value={value} index={NavTab.NOTIFICATIONS}>
                    <TechMessagesTable techMessages={techMessages} />
                </TabPanel>
                <TabPanel value={value} index={NavTab.HELP}>
                    <Help />
                </TabPanel>
            </Box>
        </>
    );

    function handleTabChanged(value: any) {
        void dispatch(changeTab(value));
    }
}
